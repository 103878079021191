<template>
  <div>
    <h4>จัดการ Redeem รูปวอลเปเปอร์</h4>
    <br />
    <b-card>
      <table-local-v-1-widget
        :items="listRedeemGroup"
        :columns="columns"
        :loading="loading"
        :actions="actions"
        @clickView="clickView"
        @clickDelete="clickDelete"
      />
      <p>* รูปแบบการขาย</p>
      <ul>
        <li>Redeem : ไม่แสดงในแอปและเว็บ (สามารถใช้ Redeem เพื่อสร้างรูปวอลเปเปอร์ได้เท่านั้น)</li>
        <li>Redeem&Coin : แสดงในแอปและเว็บ (สามารถใช้ Redeem หรือ Coin เพื่อสร้างรูปวอลเปเปอร์ได้)</li>
      </ul>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listRedeemGroup: [],
      loading: false,
      columns: [
        // {
        //   label: 'วันที่ (วัน-เดือน-ปี)',
        //   field: 'created_at',
        //   formatFn: val => this.$date(val).format('DD-MM-YYYY HH:mm'),
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
        {
          label: 'แคมเปญ',
          field: 'name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'หมายเหตุ',
          field: 'detail',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'จำนวน Code',
          field: 'count_code',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'รูปหลักที่ใช้งาน',
          field: 'topic_image_data.name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'รูปแบบการขาย',
          field: 'topic_image_data.hide_in_store',
          formatFn: val => (val === true ? 'Redeem' : 'Redeem&Coin'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'วันที่เปิดใช้งาน',
          field: 'start_date',
          formatFn: val => this.$date(val).format('DD-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'วันหมดอายุ',
          field: 'end_date',
          formatFn: val => {
            if (val) return this.$date(val).format('DD-MM-YYYY HH:mm')

            return 'ไม่มีวันหมดอายุ'
          },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      actions: [
        { keyEmit: 'clickView', iconName: 'EyeIcon', label: 'ดูเพิ่มเติม' },
        { keyEmit: 'clickDelete', iconName: 'TrashIcon', label: 'ลบ' },
      ],
    }
  },

  mounted() {
    this.fetchListRedeemGroup()
  },
  methods: {
    async fetchListRedeemGroup() {
      // console.log(this.$store.getters['AuthStore/_userRole'])
      // return
      this.loading = true

      const resp = await this.api.getV2('api/admin/redeeming-topic-data').catch(() => null)
      if (resp && resp.code === 200) {
        this.listRedeemGroup = [...resp.data]
      }

      this.loading = false
    },
    async clickView(values) {
      // console.log('clickView', values)
      // const resp = await this.api.getV2(`api/admin/redeeming-topic-data/${values.id}`).catch(() => null)

      this.$router.push({ name: 'redeem-management-details', params: { redeemMainId: values.id } })
    },
    async clickDelete(values) {
      const resp = await this.api.deleteV2(`api/admin/redeeming-topic-data/${values.id}`, this)

      if (!resp) return

      if (resp?.message) {
        this.gDisplayToast('ลบแคมเปญไม่สำเร็จ', resp?.message, 'danger')
        await this.fetchListRedeemGroup()
      } else {
        this.gDisplayToast('ลบแคมเปญสำเร็จ')
        this.listRedeemGroup = [...this.listRedeemGroup].filter(v => v.id !== values.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
